<!--产品中心-->
<template>
  <div class="product">
    <div class="productTitle">产品中心</div>

    <div class="productList">
      <div class="productItem">
        <img :src="require('@/assets/product/company.png')" alt="">
        <div>企业税贷</div>
      </div>
      <div class="productItem">
        <img :src="require('@/assets/product/house.png')" alt="">
        <div>房抵贷</div>
      </div>
      <div class="productItem">
        <img :src="require('@/assets/product/car.png')" alt="">
        <div>车信贷</div>
      </div>
<!--      <div class="productItem">-->
<!--        <div>过桥税贷</div>-->
<!--      </div>-->

      <div class="productItem">
        <img :src="require('@/assets/product/people.png')" alt="">
        <div>公积金贷</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "product-name",
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.product {
  padding: 0 250px 100px 250px;

  .productTitle {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    padding: 100px 0;
  }

  .productList {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .productItem {
      background: #fff;
      transition: 500ms;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 250px;
      height: 250px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
      }

      div{
        font-size: 25px;
        font-weight: bold;
      }

      img{
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>
