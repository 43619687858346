<template>
  <div class="footer">
    <div class="footerLeft">

      <div>
        <img :src="require('@/assets/map.png')" alt="">
        <div style="margin-top: 10px">东莞市鸿福东路1号民盈国贸中心5栋11003</div>
      </div>

      <div class="footerLeftPeople">
        <div class="footerLeftPhone">0769-22221472</div>
        <div class="footerLeftText">服务时间：周一至周日9:00 - 18:00</div>
        <div class="footerLeftText">咨询电话：15118288342</div>

        <div class="footerLeftText" style="margin-top: 20px">
          鸿创资产--中国领先的金融科技企业，致力于用先进的互联网技术和金融创新应用，让客户享受更加安全、高效、低成本的金融服务。
        </div>
      </div>

    </div>

    <div class="footerRight footerLeftText">
      您好，欢迎关注东莞市鸿创资产管理线上推广品牌：鸿创资产公司主营业务集于赎楼、评估、抵押、装修贷；公积金贷，企业税贷，保单贷，车主贷致力于先进的科技技术手段和金融创新应用，为客户提供更加安全、高效、低成本的融资服务。
      <img :src="require('@/assets/qrcode.jpg')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-name"
}
</script>

<style lang="scss">
.footer {
  background: #545454;
  padding: 50px 50px 20px 50px;
  color: #cccccc;
  display: flex;

  .footerLeftText {
    font-size: 20px;
  }

  .footerLeft {
    display: flex;
    gap: 20px;
    padding-right: 20px;
    border-right: 1px solid #cccccc;
    width: 65%;

    img {
      height: 300px;
      border-radius: 10px;
      overflow: hidden;
    }

    .footerLeftPhone {
      font-weight: bold;
      font-size: 50px;
    }

    .footerLeftPeople {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .footerRight {
    flex: 1;
    padding-left: 20px;
    text-indent: 35px;

    img{
      display: block;
      width: 75%;
      margin: 20px auto 0 auto;
    }
  }
}
</style>
