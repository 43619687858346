<template>
  <div class="bank">
    <div class="bankTitle">合作银行</div>

    <img src="https://www.scyushun.com/themes/simpleboot3/public/pc/images/bankgif1.jpg" alt="">
  </div>
</template>

<script>
export default {
  name: "bank-name"
}
</script>

<style scoped lang="scss">
  .bank{
    background: #fff;
    padding: 0 250px 250px 250px;

    .bankTitle{
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      padding: 100px 0;
    }
  }
</style>
