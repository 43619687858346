<template>
  <div class="contrner">
    <div class="header">
      <div>鸿创资产</div>

      <div>
        <i class="el-icon-phone-outline"></i>0769-22221472
      </div>
    </div>

    <el-carousel height="475px">
      <el-carousel-item v-for="item in imgList" :key="item">
        <img :src="item" alt="">
      </el-carousel-item>
    </el-carousel>

    <div class="body">
      <div class="bodyItem">
        <img :src="require('@/assets/tab1.png')" alt="">
        <div class="bodyItemTopTitle">与银行合作</div>
      </div>

      <div class="bodyItem">
        <img :src="require('@/assets/tab2.png')" alt="">
        <div class="bodyItemTopTitle">月息低至3厘2，不乱收一分钱</div>
      </div>

      <div class="bodyItem">
        <img :src="require('@/assets/tab3.png')" alt="">
        <div class="bodyItemTopTitle">额度5-500万，任您选</div>
      </div>

      <div class="bodyItem">
        <div class="bodyItemLabel">165万+</div>
        <div class="bodyItemBottomTitle">服务客户</div>
      </div>

      <div class="bodyItem">
        <div class="bodyItemLabel">1000人+</div>
        <div class="bodyItemBottomTitle">公司员工</div>
      </div>

      <div class="bodyItem">
        <div class="bodyItemLabel">6年</div>
        <div class="bodyItemBottomTitle">线上安全运营</div>
      </div>
    </div>

    <product></product>

    <bank></bank>

    <Myfooter></Myfooter>

    <div class="footerContent">
      <div>鸿创资产 © 2022 hczc168.com All Rights Reserved.</div>

      <div><a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022072332号-1 粤S-20120039</a></div>

      <div>贷款有风险，借款需谨慎；</div>
      <div>请根据个人能力合理贷款，理性消费，避免逾期；</div>
      <div>鸿创资产为金融中介服务平台，所有产品均来自银行等持牌金融机构！</div>
    </div>

  </div>
</template>

<script>
import product from "@/components/product";
import bank from "@/components/bank";
import Myfooter from "@/components/footer";

export default {
  name: 'App',
  components: {
    product, bank, Myfooter
  },
  data() {
    return {
      imgList: [
        'https://www.scyushun.com/upload/admin/20220105/bc9bae6f16509e36f2bc1dad7eb03a63.jpg',
        'https://www.scyushun.com/upload/admin/20220105/bc9bae6f16509e36f2bc1dad7eb03a63.jpg',
        'https://www.scyushun.com/upload/admin/20220105/bc9bae6f16509e36f2bc1dad7eb03a63.jpg'
      ]
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.contrner {
  display: flex;
  flex-direction: column;
  background: #fafafa;

  .header {
    display: flex;
    padding: 20px 100px;
    justify-content: space-between;
    align-items: center;

    color: #e8153e;
    font-size: 40px;
    font-weight: bold;
  }

  .body {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    padding: 0 200px;
    background: #ffffff;
    gap: 30px;

    .bodyItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 250px;
      background: #ffffff;

      img {
        width: 120px;
        height: 120px;
      }

      .bodyItemTopTitle {
        font-size: 25px;
      }

      .bodyItemLabel {
        font-weight: bold;
        font-size: 45px;
      }

      .bodyItemBottomTitle {
        margin-top: 30px;
        font-size: 25px;
      }
    }
  }

  .footerContent{
    background: #393939;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    gap: 3px;
    padding: 5px 0;
  }
}
</style>
